<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card no-bg">
      <div class="box-name-page">
        <p class="title">สิทธิ์ผู้ใช้งาน</p>
        <el-button
          id="btnAddRole"
          type="primary"
          @click="openAddRole"
          v-if="checkPermissionMore()"
        >
          เพิ่มสิทธิ์ผู้ใช้งาน
        </el-button>
      </div>
    </div>
    <div
      v-loading.lock="loading && !loadingData"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <div
        class="box-card animate__animated animate__fadeIn animate__slow mg-t-6"
        v-if="!loading"
      >
        <el-row :gutter="20">
          <!-- <el-col :span="24">
          <p class="text-right color-9c font-18">
            <span>Last Update: {{ timeSync }}</span>
          </p>
        </el-col> -->
          <el-col :span="24">
            <TableAddRole
              :data="dataRes"
              @openDailog="IsOpenDailog"
              :permissionMore="checkPermissionMore()"
            />
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog
      class="dialog-custom admin"
      title="เพิ่มสิทธิ์ผู้ใช้งาน"
      v-if="dialogAdd"
      :visible.sync="dialogAdd"
      width="70%"
      top="10vh"
      center
    >
      <el-form class="set-form" label-position="top" :model="formAdd">
        <el-form-item
          label="ชื่อสิทธิ์ผู้ใช้งาน"
          class="padding-less font-16 mg-b-4"
        >
          <el-input
            id="nameRoleAdd"
            v-model="formAdd.nameRole"
            @blur="trimInputValue(formAdd.nameRole, 'add')"
            placeholder="กรุณากรอกชื่อสิทธิ์ผู้ใช้งาน"
          ></el-input>
        </el-form-item>
        <p class="mg-y-4 font-22">จัดการสิทธิ์ผู้ใช้งาน</p>
        <ManagePermission
          :dataRes="dataManageAdd"
          @saveData="saveDataAdd"
          @closeModal="closeModal"
          type="add"
          :nameRole="formAdd.nameRole"
          class="table-manage"
        />
      </el-form>
    </el-dialog>
    <el-dialog
      class="dialog-custom admin"
      title="สิทธิ์การเข้าถึง"
      v-if="dialogView"
      :visible.sync="dialogView"
      width="70%"
      top="10vh"
      center
    >
      <div
        :class="loadingView ? 'mg-t-4' : ''"
        v-loading.lock="loadingView"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <div class="group-show-role mg-b-4" v-if="!loadingView">
          <el-button type="primary" class="font-16">{{
            arrPermission.roleName
          }}</el-button>
        </div>
        <ManagePermission
          :dataRes="arrPermission.permission"
          type="view"
          v-if="!loadingView"
          class="table-manage"
        />
      </div>
    </el-dialog>
    <el-dialog
      class="dialog-custom admin"
      title="แก้ไขสิทธิ์ผู้ใช้งาน"
      v-if="dialogEdit"
      :visible.sync="dialogEdit"
      width="70%"
      top="10vh"
      center
    >
      <div
        v-loading.lock="loadingEdit"
        :class="loadingEdit ? 'mg-t-4' : ''"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <el-form
          class="set-form"
          label-position="top"
          :model="arrPermission"
          v-if="!loadingEdit"
        >
          <el-form-item
            label="ชื่อสิทธิ์ผู้ใช้งาน"
            class="padding-less font-16 mg-b-4"
          >
            <el-input
              id="nameRoleEdit"
              v-model="arrPermission.roleName"
              @blur="trimInputValue(arrPermission.roleName, 'edit')"
              placeholder="กรุณากรอกชื่อสิทธิ์ผู้ใช้งาน"
            ></el-input>
          </el-form-item>
          <p class="mg-y-4 font-22">จัดการสิทธิ์ผู้ใช้งาน</p>
          <ManagePermission
            :dataRes="arrPermission.permission"
            @saveData="saveDataEdit"
            type="edit"
            @closeModal="closeModal"
            :nameRole="arrPermission.roleName"
            class="table-manage"
          />
        </el-form>
      </div>
    </el-dialog>
    <el-dialog
      class="dialog-custom admin detailRole"
      v-if="dialogMoreDetail"
      :visible.sync="dialogMoreDetail"
      width="90%"
      top="10vh"
      center
    >
      <template slot="title">
        <h1 class="font-34 mg-0 text-left">
          สิทธิ์ผู้ใช้งาน : {{ nameMoreView }}
        </h1>
      </template>
      <div class="scroll-table-delete">
        <TableDetail :data="dataDetailRole" />
      </div>
      <!-- </div> -->
      <!-- </div> -->
    </el-dialog>
    <el-dialog
      class="dialog-custom admin"
      title="ยืนยันการลบสิทธิ์ผู้ใช้งาน"
      v-if="dialogDelete"
      :visible.sync="dialogDelete"
      width="70%"
      top="10vh"
      center
    >
      <div
        v-loading.lock="loadingDelete"
        :class="loadingDelete ? 'mg-t-4' : ''"
        element-loading-text="กำลังโหลดข้อมูล..."
        element-loading-spinner="el-icon-loading"
      >
        <div v-if="!loadingDelete">
          <p class="mg-0 text-center mg-b-4">
            <span v-if="dataDelete.employee.length > 0">
              คุณมีข้อมูลสิทธิ์ผู้ใช้งาน
              <span class="color-light-blue"> {{ dataDelete.roleName }}</span>
              ทั้งหมด {{ dataDelete.employee.length }} ราย ดังนี้
            </span>
            <span v-else>
              คุณต้องการที่จะลบสิทธิ์ผู้ใช้งาน
              <span class="color-light-blue"> {{ dataDelete.roleName }}</span>
              หรือไม่ ?
            </span>
          </p>

          <div
            class="scroll-table-delete"
            v-if="dataDelete.employee.length > 0"
          >
            <TableDelete :data="dataDelete.employee" />
          </div>
          <p
            class="mg-0 text-center mg-t-4"
            v-if="dataDelete.employee.length > 0"
          >
            <span
              class="font-16 color-c4 text-decoration-underline cursor-pointer"
              @click="dialogDelete = false"
              >ย้อนกลับ</span
            >
          </p>
          <div class="is-flex js-end mg-t-4" v-else>
            <el-button
              id="cancleDeleteRole"
              class="font-16 btn-cancle min-100"
              @click="dialogDelete = false"
            >
              ยกเลิก</el-button
            >
            <el-button
              id="submitDeleteRole"
              type="primary"
              class="font-16 submit min-100"
              @click="deletePermission(dataDelete._id)"
              >บันทึก</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </DefaultTemplate>
</template>
<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import TableAddRole from "@/components/admin/TableAddRole";
import ManagePermission from "@/components/admin/ManagePermission";
import TableDelete from "@/components/admin/TableDelete";
import TableDetail from "@/components/admin/TableDetail";

import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefaultTemplate,
    TableAddRole,
    ManagePermission,
    TableDelete,
    TableDetail,
  },
  mounted() {
    this.$store.commit("SET_PAGE_ACTIVE", "manage-admin/add-role");
    this.getRole();
    this.getPermission();
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      permissionAll: (state) => state.permissionAll,
      permissionNow: (state) => state.permissionNow,
    }),
  },
  data() {
    return {
      loadingData: true,
      loading: false,
      loadingView: false,
      loadingEdit: false,
      loadingDelete: false,
      dialogAdd: false,
      dialogView: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogMoreDetail: false,
      dataRes: [],
      dataOpenAll: [],
      formAdd: {
        nameRole: "",
      },
      formEdit: {
        nameRole: "เทสสส",
      },
      dataDelete: [],
      dataGetPermission: [],
      dataResById: [],
      arrPermission: [],
      dataManageAdd: [],
      dataDetailRole: [],
      timeSync: "",
      nameMoreView: "",
    };
  },
  methods: {
    checkPermissionMore() {
      let ifPermission = this.permissionNow.some(
        (e) => e.pemNum == this.$route.meta.permission && e.status == 2
      );
      if (ifPermission === false) {
        return false;
      } else {
        return true;
      }
    },
    async getRole() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(`role`)
        .then((res) => {
          if (res.data.success) {
            this.dataRes = res.data.obj;
            this.loading = false;
            this.loadingData = false;
            this.timeSync = res.data.syncLatest;
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
          return res;
        })
        .catch((e) => {
          this.loadingData = false;

          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              success: false,
            },
          };
        });
      return res;
    },
    IsOpenDailog(type, id, name) {
      if (type == "view") {
        this.loadingView = true;
        this.getRoleById(id);
        this.dialogView = true;
      } else if (type == "edit") {
        this.loadingEdit = true;
        this.getRoleByIdEdit(id);
        this.dialogEdit = true;
      } else if (type == "delete") {
        this.loadingDelete = true;
        this.getDeleteRole(id);
        this.dialogDelete = true;
      } else if (type == "moreDetail") {
        this.nameMoreView = name;
        this.getMoreDetailRole(id);
      }
    },
    closeModal() {
      this.dialogAdd = false;
      this.dialogEdit = false;
    },
    async saveDataAdd(responArr) {
      let fetchPermission = [];
      let obj = {
        roleName: this.formAdd.nameRole,
        permission: fetchPermission,
      };
      responArr.forEach((e) => {
        fetchPermission.push({
          id: e._id,
          status: e.status,
        });
      });
      let hasSelect = obj.permission.find((checkObj) => checkObj.status == 0);
      if (typeof hasSelect == "undefined") {
        HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
        let res = await HTTP.post(`role`, obj)
          .then((res) => {
            if (res.data.success) {
              this.getRole();
              this.$message({
                message: "เพิ่มสิทธิ์ผู้ใช้งานสำเร็จ!",
                type: "success",
                duration: 4000,
              });
              this.dialogAdd = false;
            } else {
              if (res.data.status == 422) {
                this.$message({
                  message: "ชื่อสิทธิ์ผู้ใช้งาน ซ้ำ!",
                  type: "error",
                  duration: 4000,
                });
              }
            }
            return res;
          })
          .catch((e) => {
            if (e.response.status != 502) {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else if (e.response.data.status == 502) {
                console.log("Bad GetWay");
              } else {
                this.alertCatchError(e);
              }
            }
            return {
              data: {
                success: false,
              },
            };
          });
        return res;
      } else {
        this.$message({
          message: "กรุณาเลือกสิทธิ์การเข้าถึงของฟังก์ชัน",
          type: "error",
          duration: 4000,
        });
        return {
          data: {
            success: false,
          },
        };
      }
    },
    async saveDataEdit(responArr) {
      let fetchPermission = [];
      let data = responArr.forEach(async (e) => {
        if (e.status === 1 || e.status === 2 || e.status === 3) {
          fetchPermission.push({
            id: e.id,
            status: e.status,
          });
        }
      });
      let obj = {
        id: this.arrPermission._id,
        roleName: this.arrPermission.roleName,
        permission: fetchPermission,
      };
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.put(`role`, obj)
        .then((res) => {
          if (res.data.success) {
            this.getRole();
            this.$message({
              message: "แก้ไขสิทธิ์ผู้ใช้งานสำเร็จ!",
              type: "success",
              duration: 4000,
            });
            this.dialogEdit = false;
          } else {
            if (res.data.status == 422) {
              this.$message({
                message: "ชื่อสิทธิ์ผู้ใช้งาน ซ้ำ!",
                type: "error",
                duration: 4000,
              });
            }
          }
          return res;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return e;
        });
      return res;
    },
    getPermission() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`permission`)
        .then((res) => {
          if (res.data.success) {
            this.dataGetPermission = res.data.obj;
            this.dataGetPermission.forEach((e) => {
              let view = 0;
              let edit = 0;
              let hide = 0;
              if (e.status == 1) {
                view = 1;
              } else if (e.status == 2) {
                view = 1;
                edit = 1;
              } else if (e.status == 3) {
                hide = 1;
              }
              e.status = 0;
              e.value = {
                view: view,
                edit: edit,
                hide: hide,
              };
            });
            this.$store.commit(
              "SET_PERMISSION_ALL",
              JSON.stringify(this.dataGetPermission)
            );
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    openAddRole() {
      this.formAdd.nameRole = "";
      this.dataManageAdd = JSON.parse(this.permissionAll);
      this.dialogAdd = true;
    },
    getRoleById(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role/${id}`)
        .then((res) => {
          if (res.data.success) {
            this.dataResById = res.data.obj;
            this.dataResById.permission.forEach((e) => {
              let view = 0;
              let edit = 0;
              let hide = 0;
              if (e.status == 1) {
                view = 1;
              } else if (e.status == 2) {
                view = 1;
                edit = 1;
              } else if (e.status == 3) {
                hide = 1;
              }
              e.value = {
                view: view,
                edit: edit,
                hide: hide,
              };
            });
            let changeJson = JSON.stringify(this.dataResById);
            // console.log("changeJson", changeJson);
            this.arrPermission = JSON.parse(changeJson);

            this.loadingView = false;
            this.loadingEdit = false;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getRoleByIdEdit(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role/${id}/edit`)
        .then((res) => {
          if (res.data.success) {
            this.dataResById = res.data.obj;
            this.dataResById.permission.forEach((e) => {
              let view = 0;
              let edit = 0;
              let hide = 0;
              if (e.status == 1) {
                view = 1;
              } else if (e.status == 2) {
                view = 1;
                edit = 1;
              } else if (e.status == 3) {
                hide = 1;
              }
              e.value = {
                view: view,
                edit: edit,
                hide: hide,
              };
            });
            let changeJson = JSON.stringify(this.dataResById);
            this.arrPermission = JSON.parse(changeJson);

            this.loadingView = false;
            this.loadingEdit = false;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getDeleteRole(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`role/${id}/delete`)
        .then((res) => {
          if (res.data.success) {
            this.loadingDelete = false;
            this.dataDelete = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    async getMoreDetailRole(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.get(`role/details/${id}`)
        .then((res) => {
          if (res.data.success) {
            this.dialogMoreDetail = true;
            this.dataDetailRole = res.data.obj;
          }
          return res;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              success: false,
            },
          };
        });
      return res;
    },
    async deletePermission(id) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let res = await HTTP.delete(`role/${id}`)
        .then((res) => {
          if (res.data.success) {
            this.getRole();
            this.$message({
              message: "ลบสิทธิ์ผู้ใช้งานสำเร็จ!",
              type: "success",
              duration: 4000,
            });
            this.dialogDelete = false;
          }
          return res;
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
          return {
            data: {
              success: false,
            },
          };
        });
      return res;
    },
    trimInputValue(val, type) {
      if (type == "add") {
        this.formAdd.nameRole = val.trim();
      } else if (type == "edit") {
        this.arrPermission.roleName = val.trim();
      }
    },
  },
};
</script>