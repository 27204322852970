var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-table',{staticClass:"table-normal manage w-100",attrs:{"data":_vm.data}},[_c('el-table-column',{attrs:{"label":"สิทธิ์ผู้ใช้งาน","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('p',{staticClass:"text-left mg-0"},[_vm._v(" "+_vm._s(scope.row.roleName)+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"จำนวนฝ่าย","prop":"department","width":"150px"}}),_c('el-table-column',{attrs:{"label":"จำนวนส่วน","prop":"division","width":"150px"}}),_c('el-table-column',{attrs:{"label":"จำนวนสมาชิก","prop":"employee","width":"150px"}}),_c('el-table-column',{attrs:{"label":"","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"set-status-role"},[_c('el-button',{staticClass:"btn-color4",class:scope.row._id == 0 ? 'disabled' : '',on:{"click":function($event){return _vm.$emit('openDailog', 'moreDetail', scope.row._id,scope.row.roleName)}}},[_c('i',{staticClass:"fas fa-list-ul"})]),_c('el-button',{staticClass:"btn-color1",class:scope.row._id == 0 ? 'disabled' : '',on:{"click":function($event){return _vm.$emit('openDailog', 'view', scope.row._id)}}},[_c('i',{staticClass:"fas fa-eye"})]),(_vm.permissionMore)?_c('el-button',{staticClass:"btn-color2",class:scope.row._id == 0
                ? 'disabled'
                : scope.row.roleLevel > 0
                ? 'disabled'
                : '',on:{"click":function($event){return _vm.$emit('openDailog', 'edit', scope.row._id)}}},[_c('i',{staticClass:"fas fa-edit"})]):_vm._e(),(_vm.permissionMore)?_c('el-button',{staticClass:"btn-color3",class:scope.row._id == 0
                ? 'disabled'
                : scope.row.roleLevel > 0
                ? 'disabled'
                : '',on:{"click":function($event){return _vm.$emit('openDailog', 'delete', scope.row._id)}}},[_c('i',{staticClass:"fas fa-trash-alt"})]):_vm._e()],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }