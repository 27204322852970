<template>
  <div>
    <el-table
      class="table-normal animate__animated animate__fadeIn animate__slow"
      :data="data"
      style="width: 100%"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <p class="font-30 font-weight-500">ไม่พบข้อมูล</p>
      </template>
      <el-table-column align="center" label="ชื่อ" min-width="150px">
        <template slot-scope="scope">
          {{ scope.row.empFname }}
        </template>
      </el-table-column>
      <el-table-column align="center" label="นามสกุล" prop="empLname" min-width="150px">
      </el-table-column>
      <el-table-column align="center" label="อีเมล" prop="empEmail" min-width="170px">
      </el-table-column>
      <el-table-column align="center" label="ฝ่าย" prop="depName" min-width="150px">
      </el-table-column>
      <el-table-column align="center" label="ส่วน" prop="divName" min-width="150px">
      </el-table-column>
      <el-table-column align="center" label="ตำแหน่ง" prop="posName" min-width="170px">
      </el-table-column>
      <el-table-column align="center" label="สถานะใน ธสน." width="80px">
        <template slot-scope="scope">
          <span v-if="scope.row.empStatus == 'Active'" style="color: #38d85b"
            >Active</span
          >
          <span v-else style="color: #9c9c9c">InActive</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="สิทธิ์การเข้าถึง" width="115px" prop="accessRole">
        <template slot-scope="scope">
          <span v-if="scope.row.roleName" 
            >{{scope.row.roleName}}</span
          >
          <span v-else>-</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="สถานะใน CFOE" width="115px">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1" style="color: #38d85b"
            >ใช้งาน</span
          >
          <span v-else style="color: #9c9c9c">ละเว้น</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  props: ["data"],
};
</script>