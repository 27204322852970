<template>
  <div>
    <el-table class="table-normal animate__animated animate__fadeIn animate__slow w-100" :data="data" >
      <el-table-column label="ชื่อ" prop="empFname" min-width="150px">
      </el-table-column>
      <el-table-column label="นามสกุล" prop="empLname" min-width="150px">
      </el-table-column>
      <el-table-column
        label="ฝ่าย"
        prop="department"
        min-width="150px"
      >
      </el-table-column>
      <el-table-column
        label="ส่วน"
        prop="division"
        min-width="150px"
      >
      </el-table-column>
      <el-table-column
        label="ตำแหน่ง"
        prop="position"
        min-width="170px"
      >
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  watch: {
    data() {
      this.setData();
    },
  },
  props: ["data"],
  data() {
    return {
    };
  },
  methods: {
    setData() {
      this.data = this.data;
    },
  },
};
</script>